import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imageUrl from '../images/aa_stock_photo1.jpg';

const BULLET = '\u2022';

export class Layout extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      showAlert: false,
      alertType: "warning" // (i.e. primary, secondary, success, danger, warning, info, light, dark)
    };
  }

  static displayName = Layout.name;

  getAlert = () => {
    return (
      <div>
        <p>
          <span className="font-weight-bold">COVID-19 UPDATE: </span>
          SIMPLY AA KC HAS RESUMED ALL IN-PERSON MEETINGS AT THEIR REGULARLY SCHEDULED TIMES.  ADDITIONALLY, WE WILL CONTINUE  
          TO BROADCAST ALL IN-PERSON MEETINGS ONLINE PROVIDING A VIRTUAL MEETING OPTION UNTIL FURTHER NOTICE.
        </p>
        <p>
          FOR INFORMATION ON HOW TO JOIN THE MEETING ONLINE, PLEASE CALL <a className="font-weight-bold" href="tel:913-766-2089">(913) 766-2089</a> OR EMAIL <a className="font-weight-bold" href="mailto:simplyaakc@gmail.com">simplyaakc@gmail.com</a>.
        </p>
      </div>
    );
  }

  render () {
    return (
      <div style={{backgroundColor: "#26293a"}}>
        <NavMenu {...this.props} />
        <div className="body-wrapper" style={{ backgroundImage: `url(${imageUrl})` }}>
          {this.state.showAlert &&
            <div className="row sticky-top">
              <div className="col">
                <div className={"alert alert-" + this.state.alertType} role="alert">
                  <FontAwesomeIcon
                    style={{
                      color: "inherit",
                      width: "1.5em",
                      height: "1.5em",
                      float: "right",
                      cursor: "pointer"
                    }}
                    title="Close"
                    icon={["far", "times-circle"]}
                    onClick={() => this.setState({ showAlert: false })}
                  />
                  {this.getAlert()}
                </div>
              </div>
            </div>
          }
          <Container className="body-container">
            {this.props.children}
          </Container>
        </div>
        <footer>
            <div className="container text-center pt-2">Simply AA KC {BULLET} <a className="footer-phone-number" href="tel:913-766-2089">(913) 766-2089</a></div>
        </footer>
      </div>
    );
  }
}

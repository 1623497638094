import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Contact } from './components/Contact';
import { FAQs } from './components/FAQs';
import { Events } from './components/Events';
import { Resources } from './components/Resources';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import './custom.css';

library.add(fab, fas, far);

var showEventsPage = false;

export default class App extends Component {
  static displayName = App.name;

  render() {
    const isProduction = process.env.NODE_ENV === 'production';
    return (
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
      >
        <Layout showEventsPage={showEventsPage}>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/faqs' element={<FAQs />} />
            <Route path='/resources' element={<Resources />} />
            {showEventsPage &&
              <Route path='/events' element={<Events />} />
            }
            <Route path='/contact' element={<Contact />} />
          </Routes>
        </Layout>
      </CacheBuster>
    );
  }
}

import React, { Component } from 'react';

const BULLET = '\u2022';

export class Home extends Component {
  static displayName = Home.name;

    render() {
        return (
            <div className="row">
                <div className="col-lg-7 col-md-8 col-sm-12 content-container">
                    <h5 className="pb-2">Meeting Information</h5>
                    <ul className="list-group">
                        <li className="list-group-item list-group-item-dark"><h6>Wednesday: 7:00pm - 8:00pm</h6>
                            <ul className="list-group">
                                <li className="list-group-item list-group-item-light">Open</li>
                                <li className="list-group-item list-group-item-light">Big Book Study**</li>
                                <li className="list-group-item list-group-item-light">**First Wednesday of the Month is a Speaker Meeting</li>
                            </ul>
                        </li>
                        <li className="list-group-item list-group-item-dark mt-3"><h6>Saturday: 10:00am - 11:00am</h6>
                            <ul className="list-group">
                                <li className="list-group-item list-group-item-light">Open</li>
                                <li className="list-group-item list-group-item-light">Big Book Study</li>
                            </ul>
                        </li>
                    </ul>
                    <h5 className="pt-3 pb-2">Location</h5>
                    <ul className="list-group">
                        <li className="list-group-item list-group-item-dark"><h6>Nall Avenue Baptist Church</h6>
                            <ul className="list-group">
                                <li className="list-group-item list-group-item-light">6701 Nall Ave., Prairie Village, KS 66208 {BULLET} Room B100</li>
                                <li className="list-group-item list-group-item-light">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3100.3573371113744!2d-94.65049288464638!3d39.00716137955383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c0ec26f0b0228d%3A0xa725014c2b4d07f2!2s6701%20Nall%20Ave%2C%20Prairie%20Village%2C%20KS%2066208!5e0!3m2!1sen!2sus!4v1580089846448!5m2!1sen!2sus"
                                        width="100%"
                                        height="255"
                                        frameBorder="0"
                                        style={{ border: 0 }}
                                        allowFullScreen="">
                                    </iframe>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h5 className="pt-3 pb-2">Accessibility</h5>
                    <ul className="list-group">
                        <li className="list-group-item list-group-item-dark"><h6>Elevator</h6></li>
                        <li className="list-group-item list-group-item-dark mt-3"><h6>Wheelchair Accessible Bathrooms</h6></li>
                    </ul>
                </div>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import flyer from '../documents/2024_SAAKC_12th_Anniversary_Flyer.png';

const BULLET = '\u2022';

export class Events extends Component {
  static displayName = Events.name;

  render() {
    return (
        <div className="row">
            <div className="col-lg-7 col-md-8 col-sm-12 content-container">
                <h5 className="pb-2">Simply AA KC 12th Anniversary Celebration</h5>
                <ul className="list-group">
                    <li className="list-group-item list-group-item-dark mt-3"><h6>Saturday, October 26, 2024 - 1pm to 3pm</h6>
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-light">
                                <ul className="list-group">
                                  <li className="list-group-item list-group-item-light mt-3">
                                    <p>Nall Avenue Baptist Church - 6701 Nall Ave, Prairie Village, KS 66208</p>
                                  </li>
                                  <li className="list-group-item list-group-item-light">
                                    <iframe
                                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3100.3571544061383!2d-94.65049288477252!3d39.007165548071605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c0ec26f98dc199%3A0x78ef6aa0efb63233!2sNall%20Avenue%20Baptist%20Church!5e0!3m2!1sen!2sus!4v1628133845707!5m2!1sen!2sus"
                                      width="100%"
                                      height="255"
                                      frameBorder="0"
                                      style={{ border: 0 }}
                                      allowFullScreen="">
                                    </iframe>
                                  </li>
                                  <li className="list-group-item list-group-item-light">
                                    <p>{BULLET} 12:30pm - Doors Open</p>
                                    <p>{BULLET} 1:00pm - Food Served</p>
                                    <p>{BULLET} 1:15pm - Group History</p>
                                    <p>{BULLET} 1:30pm - Speaker Pat C. (12 yrs) - Ca&#241;yon City, CO</p>
                                    <p>{BULLET} 2:10pm - BREAK</p>
                                    <p>{BULLET} 2:20pm - Speaker Teresa C. (15 yrs) - Ca&#241;yon City, CO</p>
                                    <p>{BULLET} 3:00pm - CLOSE</p>
                                    <p>{BULLET} Group history and speaker portion will also be broadcast online here:</p>
                                      <a href="https://join.skype.com/Sq5NI8IBmtWt" target="_blank" rel="noopener">
                                        <FontAwesomeIcon
                                          style={{
                                            cursor: "pointer",
                                            marginRight: "0.5em",
                                            color: "#15AABF",
                                            width: "1.5em",
                                            height: "1.5em"
                                          }}
                                          icon={["fab", "skype"]}
                                        />Online Meeting
                                      </a>
                                  </li>
                                  <li className="list-group-item list-group-item-light">
                                    <p>Meats, dressings and drinks will be provided.  Please bring a side dish and/or dessert.</p>
                                  </li>
                                  <li className="list-group-item list-group-item-light">
                                    <p>{BULLET} Family and friends are encouraged to attend.</p>
                                    <p>{BULLET} This is an open (and awesome) meeting!</p>
                                    <p>{BULLET} For more information contact Simply AA KC @
                                      <a className="phone-number" href="tel:913-766-2089"> (913) 766-2089</a> 
                                    </p>
                                  </li>
                                  <li className="list-group-item list-group-item-light">
                                    <a href={flyer} target="_blank" rel="noopener">
                                    <FontAwesomeIcon
                                        style={{
                                            cursor: "pointer",
                                            marginRight: "0.5em",
                                            color: "#ff1b0e",
                                            width: "1.5em",
                                            height: "1.5em"
                                        }}
                                        icon={["fas", "file-pdf"]}
                                    />View Event Flyer
                                    </a>
                                  </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
  }
}

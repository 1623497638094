import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class FAQs extends Component {
  static displayName = FAQs.name;

  render() {
    return (
        <div className="row">
            <div className="col-lg-7 col-md-8 col-sm-12 content-container">
                <h5 className="pb-2">Frequently Asked Questions</h5>
                <ul className="list-group">
                    <li className="list-group-item list-group-item-dark"><h6>Q: {"What is an "}<span style={{ fontStyle: "italic" }}>{"'Open'"}</span> meeting?</h6>
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-light">
                                <p>A: 
                                    An open meeting of A.A. is a group meeting that
                                    any member of the community, alcoholic or nonalcoholic, may attend. The only obligation is that
                                    of not disclosing the names of A.A. members outside the meeting.<br /><br />
                                    A typical open meeting will usually have a {'"leader"'} and other speakers. The leader opens and closes the meeting and introduces each speaker. With
                                    rare exceptions, the speakers at an open meeting
                                    are A.A. members. Each, in turn, may review some
                                    individual drinking experiences that led to joining
                                    A.A. The speaker may also give his or her interpretation of the recovery program and suggest what
                                    sobriety has meant personally. All views expressed
                                    are purely personal, since all members of A.A.
                                    speak only for themselves.<br /><br />
                                    All of Simply AA KC{"'"}s meetings are open meetings and anyone may attend.  We simply 
                                    ask that only alcoholics share in our meetings during discussion.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li className="list-group-item list-group-item-dark mt-3"><h6>Q: {"What is a "}<span style={{ fontStyle: "italic" }}>{"'Closed'"}</span> meeting?</h6>
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-light">
                                <p>A: 
                                    A closed meeting is for A.A. members only, or
                                    for those who have a drinking problem and have
                                    a desire to stop drinking. Closed meetings give
                                    members an opportunity to discuss particular
                                    phases of their alcoholic problem that can be
                                    understood best only by other alcoholics.<br /><br />
                                    These meetings are usually conducted with
                                    maximum informality, and all members are
                                    encouraged to participate in the discussions. The
                                    closed meetings are of particular value to the newcomer, since they provide an opportunity to ask 
                                    questions that may trouble a beginner, and to get
                                    the benefit of {'"'}older{'"'} members{"'"} experience with
                                    the recovery program.
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li className="list-group-item list-group-item-dark mt-3"><h6>Q: How does a person join A.A.?</h6>
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-light">
                                <p>A: 
                                    No one {'"joins"'} A.A. in the usual sense of the term.
                                    No application for membership has to be filled out.
                                    In fact, many groups do not even keep membership records. There are no initiation fees, no dues,
                                    no assessments of any kind.<br /><br />
                                    Most people become associated with A.A. simply by attending the meetings of a particular local
                                    group. Their introduction to A.A. may have come
                                    about in one of several ways. Having come to the
                                    point in their drinking where they sincerely wanted to stop, they may have gotten in touch with A.A.
                                    voluntarily. They may have called the local A.A.
                                    office listed in the phone book, or they may have
                                    written to the General Service Office, Box 459,
                                    Grand Central Station, New York, NY 10163 or visited the A.A. website at aa.org.<br /><br />
                                    Others may have been guided to a local A.A.
                                    group by a friend, relative, doctor, or spiritual
                                    adviser.<br /><br />
                                    Usually, a newcomer to A.A. has had an opportunity to talk to one or more local members
                                    before attending the first meeting. This provides
                                    an opportunity to learn how A.A. has helped these
                                    people. The beginner gets facts about alcoholism
                                    and A.A. that help to determine whether he or
                                    she is honestly prepared to give up alcohol. The
                                    only requirement for membership is a desire to
                                    stop drinking.<br /><br />
                                    There are no membership drives in A.A. If, after
                                    attending several meetings, the newcomer decides
                                    A.A. is not for him or for her, no one will urge continuation in the association. There may be suggestions about keeping an open mind on the subject,
                                    but no one in A.A. will try to make up newcomers{"' "}
                                    minds for them. Only the alcoholic concerned
                                    can answer the question {'"'}Do I need Alcoholics
                                    Anonymous?{'"'}
                                </p>
                            </li>
                        </ul>
                    </li>
                    <li className="list-group-item list-group-item-dark mt-3"><h6>Additional Information</h6>
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-light">
                                <p>
                                    For answers to many other frequently asked questions refer to the following pamplet from www.aa.org:
                                </p>
                                <a href="https://www.aa.org/assets/en_US/p-2_faqAboutAA.pdf" target="_blank" rel="noopener">
                                    <FontAwesomeIcon
                                        style={{
                                            cursor: "pointer",
                                            marginRight: "0.5em",
                                            color: "#ff1b0e",
                                            width: "1.5em",
                                            height: "1.5em"
                                        }}
                                        icon={["fas", "file-pdf"]}
                                    />Frequently Asked Questions About A.A.
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
  }
}

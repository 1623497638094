import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import imageUrl from '../images/aa_circle_triangle.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);
    
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow" light>
          <NavbarBrand tag={Link} to="/">
            <img src={imageUrl} alt="AA" width="25px" height="25px" className="img-responsive header-icon" />Simply AA KC
          </NavbarBrand>
          <FontAwesomeIcon
              className="mr-2 custom-navbar-toggler"
              onClick={this.toggleNavbar}
              style={{
                  cursor: "pointer",
                  color: "white",
                  width: "1.5em",
                  height: "1.5em"
              }}
              icon={["fas", "bars"]}
          />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/faqs">FAQs</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/resources">Resources</NavLink>
              </NavItem>
              {this.props.showEventsPage &&
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/events">Events</NavLink>
                </NavItem>
              }
              <NavItem>
                <NavLink tag={Link} className="text-dark" to="/contact">Contact</NavLink>
              </NavItem>
            </ul>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class Contact extends Component {
  static displayName = Contact.name;

  render() {
    return (
        <div className="row">
            <div className="col-lg-7 col-md-8 col-sm-12 content-container">
                <h5 className="pb-2">Contact</h5>
                <ul className="list-group">
                    <li className="list-group-item list-group-item-warning">
                        <div className="row pb-2">
                            <div className="col-1">
                                <FontAwesomeIcon
                                    style={{
                                        color: "#2CBC00",
                                        width: "2em",
                                        height: "2em"
                                    }}
                                    icon={["fas", "phone-alt"]}
                                />
                            </div>
                            <div className="col" style={{ margin: "0.25em 0 0 0.5em" }}>
                                <h6>24-Hour Hotline</h6>
                            </div>
                        </div>
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-light">
                                <div className="row">
                                    <div className="col">
                                        <a className="phone-number" href="tel:816-471-7229">
                                            <h3>(816) 471-7229</h3>
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li className="list-group-item list-group-item-dark mt-3">
                        <div className="row pb-2">
                            <div className="col" style={{ marginTop: "0.25em" }}>
                                <h6>Simply AA KC</h6>
                            </div>
                        </div>
                        <ul className="list-group">
                            <li className="list-group-item list-group-item-light">
                                <div className="row pb-2">
                                    <div className="col-1">
                                        <FontAwesomeIcon
                                            style={{
                                                color: "#2CBC00",
                                                width: "2em",
                                                height: "2em"
                                            }}
                                            icon={["fas", "phone-alt"]}
                                        />
                                    </div>
                                    <div className="col" style={{ margin: "0.25em 0 0 0.5em" }}>
                                        <h6>Phone</h6>
                                    </div>
                                </div>
                                <ul className="list-group">
                                    <li className="list-group-item list-group-item-light">
                                        <div className="row">
                                            <div className="col">
                                                <a className="phone-number" href="tel:913-766-2089">
                                                    <h6>(913) 766-2089</h6>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li className="list-group-item list-group-item-light mt-3">
                                <div className="row pb-2">
                                    <div className="col-1">
                                        <FontAwesomeIcon
                                            style={{
                                                color: "#45B4E2",
                                                width: "2em",
                                                height: "2em"
                                            }}
                                            icon={["far", "envelope"]}
                                        />
                                    </div>
                                    <div className="col" style={{ margin: "0.25em 0 0 0.5em" }}>
                                        <h6>Email</h6>
                                    </div>
                                </div>
                                <ul className="list-group">
                                    <li className="list-group-item list-group-item-light">
                                        <div className="row">
                                            <div className="col">
                                                <a className="phone-number" href="mailto:simplyaakc@gmail.com">
                                                    <h6>simplyaakc@gmail.com</h6>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    );
  }
}

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import meetingGuideUrl from '../images/meeting_guide_app.png';
import kansasCityUrl from '../images/kansas_city.png';

export class Resources extends Component {
    static displayName = Resources.name;

    render() {
        return (
            <div className="row">
                <div className="col-lg-7 col-md-8 col-sm-12 content-container">
                    <h5 className="pb-2">Resources</h5>
                    <ul className="list-group">
                        <li className="list-group-item list-group-item-dark">
                            <div className="row pb-2">
                                <div className="col-lg-1 col-md-2 col-sm-2">
                                    <img src={meetingGuideUrl}
                                        alt="Meeting Guide App"
                                        width="50px"
                                        height="50px"
                                        className="img-responsive"
                                    />
                                </div>
                                <div className="col" style={{marginTop: "0.75em"}}>
                                    <h6>Meeting Guide App</h6>
                                </div>
                            </div>
                            <ul className="list-group">
                                <li className="list-group-item list-group-item-light">
                                    <div className="row">
                                        <div className="col">
                                            <p>Find nearby meeting locations and times from anywhere on your mobile device!</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-2 mr-2">
                                            <a href="https://apps.apple.com/us/app/meeting-guide/id1042822181" target="_blank" rel="noopener">
                                                <FontAwesomeIcon
                                                    style={{
                                                        cursor: "pointer",
                                                        color: "#139af0",
                                                        width: "3em",
                                                        height: "3em"
                                                    }}
                                                    icon={["fab", "app-store-ios"]}
                                                />
                                            </a>
                                        </div>
                                        <div
                                            className="col-lg-10 col-md-8 col-sm-4"
                                            style={{ paddingTop: "0.65em" }}
                                        >
                                            <p>iPhone Download</p>
                                        </div>
                                    </div>
                                    <div className="row pt-3">
                                        <div className="col-lg-1 col-md-2 col-sm-2 mr-2">
                                            <a href="https://play.google.com/store/apps/details?id=org.meetingguide&hl=en_US" target="_blank" rel="noopener">
                                                <FontAwesomeIcon
                                                    style={{
                                                        cursor: "pointer",
                                                        color: "#aac148",
                                                        width: "3em",
                                                        height: "3em"
                                                    }}
                                                    icon={["fab", "android"]}
                                                />
                                            </a>
                                        </div>
                                        <div
                                            className="col-lg-10 col-md-8 col-sm-4"
                                            style={{ paddingTop: "0.5em" }}
                                        >
                                            <p>Android Download</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li className="list-group-item list-group-item-dark mt-3">
                            <div className="row pb-3">
                                <div className=" col-lg-3 col-md-5 col-sm-2">
                                    <img src={kansasCityUrl}
                                        alt="Kansas City Area Central Office"
                                        width="139px"
                                        height="50px"
                                        className="img-responsive"
                                    />
                                </div>
                                <div className="col" style={{ marginTop: "1em" }}>
                                    <h6>Kansas City Area Central Office</h6>
                                </div>
                            </div>
                            <ul className="list-group">
                                <li className="list-group-item list-group-item-light">
                                    <div className="row">
                                        <div className="col">
                                            <p>To search for meetings, upcoming events, newsletters, literature, and additional 
                                                AA resources for the greater Kansas City and surronding areas, visit the following
                                                website:
                                            </p>
                                            <a href="https://www.kc-aa.org" target="_blank" rel="noopener">www.kc-aa.org</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
